<template lang="pug">
.stepper(:class="{ extended, 'overlay': overlay && !value, disabled, 'hide-add-subtract-btn' : removeAddSubstractButton }" @click="clickHiddenButtons")
  transition(name="fade")
    .stepper--overlay(v-if="overlay && !value" @click="modify(value + 1)") {{ placeholderText }}
  .stepper--amount(
    v-if="extended"
    :class="{ 'is-placeholder': !value && placeholderText }"
  )
    span.stepper--amount-prefix {{ prefix }}
    input.stepper--amount-input(
      v-if="!removeAddSubstractButton"
      :value="computedValue"
      type="number"
      @input="modifyInput"
    )
    span( v-else ) {{ value || placeholderText }}
  transition(name="stepper")
    button.stepper--button.minus(
      v-if="extended"
      :disabled="disabled"
      @click="modify(value - 1)"
    )
  button.stepper--button.plus(
    :disabled="disabled"
    @click="modify(value + 1)"
  )
</template>

<script>
export const debounce = (func, wait = 1500) => {
  let timer;
  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, wait);
  };
};

export default {
  name: 'ProductStepper',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    hideAddSubtractBtn: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      removeAddSubstractButton: this.hideAddSubtractBtn,
    };
  },
  computed: {
    extended() {
      return this.value > 0 || this.alwaysOpen;
    },
    computedValue() {
      return this.value;
    },
  },
  watch: {
    hideAddSubtractBtn(newVal) {
      this.removeAddSubstractButton = newVal;
    },
    removeAddSubstractButton(newVal) {
      if (newVal) {
        clearTimeout(this.removeAddSubstrackTimeout);
      }
    },
  },
  methods: {
    modify(value) {
      if (value >= 0 && !this.disabled) {
        this.$emit('input', value);
      }
    },
    modifyInput: debounce(function (value) {
      const updateValue = Number(value.target.value);
      if (value.target.value.length > 0 && value.target.value >= 0 && !this.disabled) {
        this.$emit('input', updateValue);
      }
    }, 1000),
    clickHiddenButtons() {
      if (this.hideAddSubtractBtn) {
        this.removeAddSubstractButton = false;
        this.removeAddSubstrackTimeout = setTimeout(() => {
          this.removeAddSubstractButton = true;
        }, 9000);
      }
    },
  },
};
</script>

<style lang="scss">
.stepper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px;
  border: 1px solid transparent;
  border-radius: 36px;
  background: $white;
  transition: $transition;

  &.extended {
    overflow: hidden;
    min-width: 105px;
    border: 1px solid $champagne;

    &.hide-add-subtract-btn {
      min-width: 60px;
      height: 36px;
      cursor: pointer;

      .stepper--amount-prefix {
        margin: 0;
      }

      .stepper--button {
        display: none;
      }
    }
  }

  &.overlay {
    border: 1px solid transparent;
  }

  &.disabled {

    .stepper--overlay {
      cursor: not-allowed;
      background: $champagne;
    }
  }

  &--overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    color: $white;
    cursor: pointer;
    background: $fulvous;

    &::after {
      content: '\f067';
      margin: 0 0 0 10px;
      font-weight: 100;
      font-family: $fontAwesome;
    }
  }

  &--amount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 36px;
    padding: 0 5px;
    color: $blackOlive;
    font-weight: 500;
    font-size: 15px;

    &.is-placeholder {
      padding: 0 10px;
      font-weight: 400;
      font-size: 12px;
    }

    &-prefix {
      margin: 0 10px 0 0;
    }

    &-input {
      width: 35px;
      border: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  &--button {
    min-width: 36px;
    height: 36px;
    padding: 0;
    border: none;
    border-radius: 50%;
    outline: 0;
    font-size: 18px;
    cursor: pointer;
    background: $fulvous;

    &:disabled {
      cursor: not-allowed;
      background: $almond;
    }

    &.plus, &.minus {
      transition: all 0.1s 0s ease;

      &::before {
        color: $white;
        font-family: $fontAwesome;
      }
      @media only screen and (min-width: 1024px) {
        // hover styles only on desktop

        &:not(:disabled):hover {
          background: darken($fulvous, 5%);
        }

        &:not(:disabled):active {
          font-size: 13px;
          background: darken($fulvous, 20%);
        }
      }
    }

    &.plus {

      &::before {
        content: '\f067';
      }
    }

    &.minus {
      margin: 0 3px 0 0;

      &::before {
        content: '\f068';
      }
    }
  }
}
</style>
